import React, { useState } from 'react';
import { IonIcon, IonSpinner } from '@ionic/react';

interface ForgotPasswordScreenProps {
    onBack: () => void;
}

const ForgotPasswordScreen: React.FC<ForgotPasswordScreenProps> = ({ onBack }) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState('');

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!isValidPhoneNumber(phoneNumber)) {
            setPhoneNumberError('Invalid phone number');
            return;
        }
        setIsLoading(true);
        // TODO: Implement password reset logic here
        console.log('Reset password for:', phoneNumber);
        setIsLoading(false);
    };

    const isValidPhoneNumber = (phone: string) => {
        const phoneRegex = /^[0-9]{10}$/;
        return phoneRegex.test(phone);
    };

    return (
        <div className="w-full">
            <h2 className="text-2xl font-bold mb-4">Reset Password</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label htmlFor="phoneNumber" className="text-black text-lg block mb-1">Phone Number</label>
                    <div className="flex">
                        <div className="bg-gray-200 p-2 rounded-l-md flex items-center justify-center">
                            +30
                        </div>
                        <input
                            type="tel"
                            id="phoneNumber"
                            name="phoneNumber"
                            placeholder="Enter your phone number"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            className="w-full p-2 border-2 rounded-r-md text-black focus:outline-none focus:border-main"
                        />
                    </div>
                    {phoneNumberError && <p className="text-red-500 text-xs mt-1">{phoneNumberError}</p>}
                </div>
                <button
                    type="submit"
                    disabled={isLoading}
                    className={`w-full px-4 py-2 bg-main text-white cursor-pointer mt-3 text-m font-semibold rounded transition-colors shadow-md hover:bg-[#0000ff] duration-200 focus:outline-none ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                    {isLoading ? <IonSpinner name="crescent" className="h-[18px]" /> : 'Reset Password'}
                </button>
            </form>
            <div className='text-main text-sm text-end mt-4 mx-2 cursor-pointer  flex gap-1 items-center justify-end' onClick={onBack}>
                <div className='border-b border-b-white hover:border-b-main flex items-center'>
                <IonIcon icon='arrow-back' className='mr-1 mt-0.5 ' />
                <div>
                    Back to login
                </div>
                </div>


            </div>

        </div>
    );
};

export default ForgotPasswordScreen;