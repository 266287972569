import React, { useState, useEffect, useRef, useContext } from "react";
import LogoSvg from "../Misc/LogoSVG/LogoSVG";
import { IonIcon } from "@ionic/react";
import { Link, useLocation } from "react-router-dom";
import MyBookingsLink from "./MyBookingsLink/MyBookingsLink";
import AllBookingsLink from "./AllBookingsLink/AllBookingsLink";
import { Tabs, TabsList, TabsContent, TabsTrigger } from "../../@/components/ui/tabs";
import { useNavigate } from "react-router-dom";
import { useReservations } from '../../utils/ReservationsContext/ReservationsContext';
import { AuthContext } from '../../utils/AuthContext';
import prebookingLogo from '../../assets/images/logos/prebooking_logo_scaled_new.png';
import PageTitle from './PageTitle/PageTitle';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger
} from "../../@/components/ui/dropdown-menu";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
    DialogClose
} from "../../@/components/ui/dialog";
import DarkModeToggle from "./DarkModeToggle/DarkModeToggle";

interface NavigationProps {
    pageTitle: string;
}

const Navigation: React.FC<NavigationProps> = ({ pageTitle }) => {
    const userAgent = navigator.userAgent;
    const location = useLocation();
    const [activeLink, setActiveLink] = useState<JSX.Element>(<></>);
    const [icon, setIcon] = useState<JSX.Element>(<></>);
    const navigate = useNavigate();
    const [bookingNum, setBookingNum] = useState<number>(0);
    const fetched = useRef(false);
    const { driverId, reservations, numberOfReservations, fetchReservations, makeReservation, availableBookings } = useReservations();
    let { logoutUser } = useContext(AuthContext) as any;
    const [isChangeInfoDialogOpen, setIsChangeInfoDialogOpen] = useState(false);

    useEffect(() => {
        if (location.pathname === '/my-bookings') {
            setActiveLink(<AllBookingsLink />);
        } else if (location.pathname === '/') {
            setActiveLink(<MyBookingsLink />);
        }
    }, [location.pathname]);

    useEffect(() => {
        if (/iPhone/i.test(userAgent)) {
            setIcon(<IonIcon className='text-base' icon='logo-apple' />);
        } else if (/Android/i.test(userAgent)) {
            setIcon(<IonIcon className='text-base' icon='logo-android' />);
        }
        setIcon(<IonIcon className='text-base' icon='logo-apple' />);
    }, []);

    return (
        <div className="max-w-desktop md:px-4 m-auto w-full pt-1 pb-2 md:py-6 flex flex-col gap-3 select-none">
            <div className="w-full flex items-center justify-between px-4 md:px-0">

                <div className="w-8 md:w-1/3 md:hidden mt-3">
                <DarkModeToggle className=" md:hidden"/>
                    {/* <button className="bg-main text-white text-xs px-2 py-2 rounded-full flex items-center justify-center">
                        {icon}
                    </button> */}
                </div>

                {/* Logo */}
                <div className="flex items-center justify-center">
                    <img src={prebookingLogo} alt="" className="h-auto w-auto max-h-[3.2rem] object-contain" />
                </div>
                <div className="hidden md:flex">

                <PageTitle title={pageTitle} className="" />

                </div>
                {/* More options menu */}
                <div className="w-8 md:w-auto flex justify-end md:gap-8 md:items-center">

                    <div className="hidden md:flex flex-col items-center justify-center h-full cursor-pointer">
                        <div className="w-full flex justify-center px-4 md:px-0">
                            <Tabs defaultValue={location.pathname === '/my-bookings' ? 'my-bookings' : 'all-bookings'} className="w-full md:w-auto">
                                <TabsList className="w-full md:w-auto">
                                    <TabsTrigger value="all-bookings" className="w-1/2 md:w-auto rounded-l-md px-2.5 shadow-md" onClick={() => navigate("/")}>Όλα</TabsTrigger>
                                    <TabsTrigger value="my-bookings" className="w-1/2 md:w-auto rounded-r-md px-2.5 relative shadow-md" onClick={() => navigate("/my-bookings")}>
                                        {
                                            reservations.length > 0 &&
                                            <div className="absolute top-[-10px] right-[-5px] h-5 w-5 rounded-full bg-red-500 text-white text-center text-xxs">
                                                <div>
                                                    {reservations.length}
                                                </div>
                                            </div>
                                        }
                                        <div>
                                            Αποδεκτά
                                        </div>
                                    </TabsTrigger>
                                </TabsList>
                            </Tabs>
                        </div>
                    </div>
                    <DarkModeToggle className="hidden md:flex"/>
                    <DropdownMenu>
                        <DropdownMenuTrigger className="focus:outline-none md:mb-2">
                            <IonIcon icon="ellipsis-horizontal" className="text-2xl mt-4 dark:text-neutral-400" />
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="bg-white mr-2 dark:bg-neutral-500 dark:border-neutral-500 dark:text-neutral-100">
                            <DropdownMenuItem onClick={() => setIsChangeInfoDialogOpen(true)} className="cursor-pointer hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-md">
                                <IonIcon icon='information-circle' className="mr-2" />
                                Αλλαγή στοιχείων
                            </DropdownMenuItem>
                            <DropdownMenuItem onClick={logoutUser} className="cursor-pointer hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-md">
                                <IonIcon icon='log-out' className="mr-2" />
                                Έξοδος
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            </div>

            {/* Add PageTitle here, visible only on mobile */}
            <div className="md:hidden">
                <PageTitle title={pageTitle} className="" />
            </div>

            <div className="md:hidden flex flex-col items-center justify-center h-full cursor-pointer">
                <div className="w-full flex justify-center px-4 md:px-0">
                    <Tabs defaultValue={location.pathname === '/my-bookings' ? 'my-bookings' : 'all-bookings'} className="w-full md:w-auto">
                        <TabsList className="w-full md:w-auto">
                            <TabsTrigger value="all-bookings" className="w-1/2 md:w-auto rounded-l-md px-2.5 shadow-md" onClick={() => navigate("/")}>Όλα</TabsTrigger>
                            <TabsTrigger value="my-bookings" className="w-1/2 md:w-auto rounded-r-md px-2.5 relative shadow-md" onClick={() => navigate("/my-bookings")}>
                                {
                                    reservations.length > 0 &&
                                    <div className="absolute top-[-10px] right-[-5px] h-5 w-5 rounded-full bg-red-500 text-white text-center text-xxs">
                                        <div>
                                            {reservations.length}
                                        </div>
                                    </div>
                                }
                                <div>
                                    Αποδεκτά
                                </div>
                            </TabsTrigger>
                        </TabsList>
                    </Tabs>
                </div>
            </div>
            <Dialog open={isChangeInfoDialogOpen} onOpenChange={setIsChangeInfoDialogOpen}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle className="">Αλλαγή Στοιχείων</DialogTitle>
                        <DialogDescription>
                            Για να αλλάξετε τα στοιχεία σας, παρακαλούμε στείλτε ένα email στο info@prebooking.gr
                        </DialogDescription>
                    </DialogHeader>
                    <DialogClose asChild className="mt-[-2px]">
                        <button className="bg-main text-white text-sm px-4 py-2 rounded-md mt-4">Κλείσιμο</button>
                    </DialogClose>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default Navigation;