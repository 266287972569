import React, { useState, useEffect, useRef } from 'react';
import BookingRow from '../DriverReservationsPage/DriverReservationsItem/DriverReservationsItem';
import SkeletonLoader from '../../Components/Misc/SkeletonLoader/SkeletonLoader';
import AllBookingsHeader from '../../Components/BookingHeader/BookingHeader';
import { Booking } from '../../../interfaces';
import { ACTIVE_URL } from '../../constants';
import { useReservations } from '../../utils/ReservationsContext/ReservationsContext';
import AllPreBookingsItem from './AllPreBookingsItem/AllPreBookingsItem';
import { sortBookingsByPickupTime } from '../../utils/BookingSortByPickup/BookingSortByPickup';

const AllPreBookings = () => {
    const [bookings, setBookings] = useState<Booking[]>([]);
    const [search, setSearch] = useState('');
    const fetched = useRef(false);
    const { driverId, reservations, numberOfReservations, fetchReservations, makeReservation, availableBookings } = useReservations();

    const sortedBookings = sortBookingsByPickupTime(availableBookings);

    return (
        <div className=''>
            <div className='max-w-desktop m-auto my-4'>
                <div className='flex flex-col gap-2'>
                    <AllBookingsHeader />
                    {Array.isArray(sortedBookings) && sortedBookings.length > 0 ? (
                        sortedBookings.map((booking, index) => (
                            <div className="w-full flex justify-center select-none mb-2" key={index}>
                                <AllPreBookingsItem booking={booking} />
                            </div>
                        ))
                    ) : (
                        <div className="min-h-[30vh] flex items-center justify-center px-4 text-neutral-500 dark:text-neutral-500">
                            Δεν υπάρχουν διαθέσιμα ραντεβού
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default AllPreBookings;