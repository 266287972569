import React, { createContext, useState, useEffect } from 'react';
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom';
import { ACTIVE_URL } from '../constants';
import { useToast } from '../@/components/ui/use-toast';

type User = {
    user_id: number;
    phone_number: string;
};

type AuthTokens = {
    access: string;
    refresh: string;
};

export type AuthContextType = {
    user: User | null;
    authTokens: AuthTokens | null;
    driverId: number | null;
    loginUser: (phone_number: string, password: string) => Promise<void>;
    logoutUser: () => void;
};

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export default AuthContext;

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { toast } = useToast()
    const storedAuthTokens = localStorage.getItem('authTokens');
    const [authTokens, setAuthTokens] = useState<AuthTokens | null>(
        storedAuthTokens ? JSON.parse(storedAuthTokens) : null
    );

    const storedAuthTokensUser = localStorage.getItem('authTokens');
    const [user, setUser] = useState<User | null>(
        storedAuthTokensUser ? jwtDecode<User>(JSON.parse(storedAuthTokensUser).access) : null
    );
    const [driverId, setDriverId] = useState<number | null>(() => {
        const storedDriverId = localStorage.getItem('driverId');
        return storedDriverId ? parseInt(storedDriverId, 10) : null;
    });
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const loginUser = async (phone_number: string, password: string) => {
        try {
            const response = await fetch(`${ACTIVE_URL}/api/user/token/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 'phone_number': phone_number, 'password': password })
            });
            const data = await response.json();

            if (response.status === 200) {
                setAuthTokens(data);
                const decodedUser = jwtDecode<User>(data.access);
                setUser(decodedUser);
                setDriverId(decodedUser.user_id);
                localStorage.setItem('authTokens', JSON.stringify(data));
                localStorage.setItem('driverId', decodedUser.user_id.toString());
                navigate('/');
            } else {
                toast({
                    title: 'Login Failed',
                    description: 'Please check your credentials and try again.',
                    variant: 'destructive',
                    duration: 2000,
                });
            }
        } catch (error) {
            console.error('Login error:', error);
            toast({
                title: 'Login Error',
                description: 'An unexpected error occurred. Please try again later.',
                variant: 'destructive',
                duration: 2000,
            });
        }
    };

    const logoutUser = () => {
        setAuthTokens(null);
        setUser(null);
        setDriverId(null);
        localStorage.removeItem('authTokens');
        localStorage.removeItem('driverId');
        navigate('/login');
    };

    const updateToken = async () => {
        try {
            const response = await fetch(`${ACTIVE_URL}/api/user/token/refresh/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ refresh: authTokens?.refresh }),
            });

            if (response.status === 200) {
                const data = await response.json();
                setAuthTokens(data);
                const decodedUser = jwtDecode<User>(data.access);
                setUser(decodedUser);
                setDriverId(decodedUser.user_id);
                localStorage.setItem('authTokens', JSON.stringify(data));
                localStorage.setItem('driverId', decodedUser.user_id.toString());
            } else if (response.status === 401) {
                console.error('Token refresh failed: Unauthorized (401)');
                logoutUser();
            } else {
                console.error('Token refresh failed:', response.status, response.statusText);
            }

            if (loading) {
                setLoading(false);
            }
        } catch (error) {
            console.error('Error refreshing token:', error);
            logoutUser();
        }
    };

    useEffect(() => {
        if (loading) {
            updateToken();
        }

        const four_hours = 1000 * 60 * 60 * 4;

        const interval = setInterval(() => {
            if (authTokens) {
                updateToken();
            }
        }, four_hours);

        return () => clearInterval(interval);
    }, [authTokens, loading]);

    const contextData: AuthContextType = {
        user,
        authTokens,
        driverId,
        loginUser,
        logoutUser
    };

    return (
        <AuthContext.Provider value={contextData}>
            {loading ? null : children}
        </AuthContext.Provider>
    );
};