import { IonIcon } from '@ionic/react';
import React from 'react';

const AllBookingsHeader = () => {
    return (
        <div className="hidden lg:flex h-12 mx-4 border border-neutral-300 rounded-lg px-2 items-center justify-start max-w-desktop dark:border-neutral-500">
            <div className="w-[15%] flex gap-1.5 items-center justify-start">
                {/* <IonIcon icon='time-outline' /> */}

            </div>
            <div className="w-[15%] flex gap-1.5 items-center justify-start">
                <IonIcon icon='calendar' />
                Date
            </div>
            <div className="w-[15%] flex gap-1.5 items-center justify-start">
                <IonIcon icon='time-outline' />
                Time
            </div>
            <div className="w-[20%] flex gap-2 items-center justify-start">
                <IonIcon icon='location' />
                From
            </div>
            <div className="w-[20%] flex gap-2 items-center justify-start">
                <IonIcon icon='navigate' />
                Destination
            </div>
            <div className="w-[15%] flex gap-2 items-center justify-start">
                <IonIcon icon='ellipsis-horizontal' />
                Actions
            </div>
        </div>
    )
}

export default AllBookingsHeader;