import React, { useState, useEffect } from 'react';
import { IonIcon } from '@ionic/react';

interface CountdownTimerProps {
    targetDate: string;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ targetDate }) => {
    const [timeLeft, setTimeLeft] = useState<string>('');
    const [isLessThanDay, setIsLessThanDay] = useState<boolean>(false);

    useEffect(() => {
        const calculateTimeLeft = () => {
            const now = new Date();
            const target = new Date(targetDate);
            const difference = target.getTime() - now.getTime();

            if (difference > 0) {
                const days = Math.floor(difference / (1000 * 60 * 60 * 24));
                const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

                let timeString = '';
                if (days > 0) timeString += `${days}d `;
                if (hours > 0 || days > 0) timeString += `${hours}h `;
                timeString += `${minutes}m`;

                setTimeLeft(timeString.trim());
                setIsLessThanDay(days < 1);
            } else {
                setTimeLeft('Arrived');
                setIsLessThanDay(true);
            }
        };

        calculateTimeLeft();
        const timer = setInterval(calculateTimeLeft, 1000); // Update every second
        return () => clearInterval(timer);
    }, [targetDate]);

    return (
        <div className={`text-sm py-[3px] px-2 items-center justify-center flex gap-1 rounded-md text-white ${isLessThanDay ? 'bg-red-500' : 'bg-main'} w-[118px]`}>
            <IonIcon icon={isLessThanDay ? 'alarm-outline' : 'time-outline'} className="text-sm" />
            <span className="text-right">{timeLeft}</span>
        </div>
    );
};

export default CountdownTimer;