import React from 'react';

interface PageTitleProps {
    title: string;
    className?: string;
}

const PageTitle: React.FC<PageTitleProps> = ({ title, className = '' }) => {
    return (
        <div className="page-title">
            <h1 className={`px-2 text-center sm:px-0 text-xl sm:text-2xl ${className}`}>
                {title}
            </h1>
        </div>
    );
}

export default PageTitle;