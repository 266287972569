import { IonIcon } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ACTIVE_URL } from "../../../constants";
import { Booking } from "../../../../interfaces";

const AllBookingsLink = () => {
    const [bookings, setBookings] = useState<Booking[]>([]);
    const fetched = useRef(false);

    // useEffect(() => {
    //     const fetchResponses = async () => {
    //         try {

    //             const response = await fetch(`${ACTIVE_URL}/api/get_available_bookings/`, {
    //                 method: 'GET',
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                 }
    //             });
    //             const data = await response.json();
    //             if (response.ok) {
    //                 setBookings(data);
    //             } else {
    //                 console.error('Failed to fetch responses');
    //             }
    //         } catch (error) {
    //             console.error('Error fetching responses:', error);
    //         }
    //     }

    //     if (!fetched.current) {
    //         fetchResponses();
    //         fetched.current = true;
    //     }
    // }, []);

    return (
        <Link to='/' className="cursor-pointer py-2 text-white bg-main px-4 text-sm rounded-md flex gap-2 items-center justify-center">
            <div className="h-full flex items-center justify-center">
                <IonIcon icon='list' />
            </div>
            <div>
                All Bookings
            </div>
        </Link>
    )
}

export default AllBookingsLink;