import { IonIcon } from "@ionic/react";
import { Link } from "react-router-dom";


const MyBookingsLink = () => {

    return (
        <Link to='/my-bookings' className="cursor-pointer py-2 text-white bg-main px-4 text-sm rounded-md flex gap-2 items-center justify-center">
            <div className="h-full flex items-center justify-center">
                <IonIcon icon='person' />
            </div>
            <div>
                My Bookings
            </div>
        </Link>
    );
}

export default MyBookingsLink;