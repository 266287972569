import React, { useEffect, useRef, useState } from "react";
import BookingHeader from "../../Components/BookingHeader/BookingHeader";
import SkeletonLoader from "../../Components/Misc/SkeletonLoader/SkeletonLoader";
import { IonIcon } from "@ionic/react";
import { ACTIVE_URL } from "../../constants";
import { Booking } from "../../../interfaces";
import { useReservations } from '../../utils/ReservationsContext/ReservationsContext';
import DriverReservationsItem from "./DriverReservationsItem/DriverReservationsItem";
import { sortBookingsByPickupTime } from '../../utils/BookingSortByPickup/BookingSortByPickup';

const DriverReservationsPage = () => {
    const [bookings, setBookings] = useState<Booking[]>([]);
    const [search, setSearch] = useState('');
    const fetched = useRef(false);
    const { driverId, reservations, numberOfReservations, fetchReservations, makeReservation, availableBookings } = useReservations();

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString().slice(-2);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${day}/${month}/${year} ${hours}:${minutes}`;
    }

    const sortedReservations = sortBookingsByPickupTime(reservations);

    return (
        <div className=''>
            <div className='max-w-desktop m-auto my-4'>
                <div className='hidden md:flex flex-col gap-2.5 mb-4'>
                    <BookingHeader />
                </div>
                <div className="flex flex-col gap-2">
                    {Array.isArray(sortedReservations) && sortedReservations.length > 0 ? (
                        sortedReservations.map((booking, index) => (
                            <div className="w-full flex justify-center select-none mb-2" key={index}>
                                <DriverReservationsItem booking={booking} />
                            </div>
                        ))
                    ) : (
                        <div className="min-h-[30vh] flex items-center justify-center px-4 text-neutral-500">Δεν υπάρχει κανένα ενεργό ραντεβού</div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default DriverReservationsPage;