import React, { useContext, FormEvent, useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { IonIcon, IonSpinner } from '@ionic/react';
import AuthContext from '../../utils/AuthContext';
import driverImg from '../../assets/images/driver_img_cut.jpg'
import LogoSvg from '../../Components/Misc/LogoSVG/LogoSVG';
import { Toaster } from '../../@/components/ui/toaster';
import prebookingLogo from '../../assets/images/logos/prebooking_logo_scaled_new.png'
import ForgotPasswordScreen from './ForgotPassword/ForgotPassword';
import { motion, AnimatePresence } from 'framer-motion';

const LoginPage: React.FC = () => {
  const authContext = useContext(AuthContext);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [currentScreen, setCurrentScreen] = useState<'login' | 'forgotPassword'>('login');

  const handleForgotPasswordClick = () => {
    setCurrentScreen('forgotPassword');
  };

  const handleBackToLogin = () => {
    setCurrentScreen('login');
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const autoPhone = params.get('phone');
    const autoPassword = params.get('password');
    if (autoPhone) setPhoneNumber(autoPhone);
    if (autoPassword) setPassword(autoPassword);
    const autoLogin = params.get('autoLogin');
    if (autoPhone && autoPassword && autoLogin === 'true') {
      handleAutoLogin(autoPhone, autoPassword);
    }
  }, [location]);

  const handleAutoLogin = async (autoPhone: string, autoPassword: string) => {
    setIsSubmitted(true);
    if (!isValidPhoneNumber(autoPhone)) {
      setPhoneNumberError('Invalid phone number');
      return;
    }
    setIsLoading(true);
    try {
      await authContext?.loginUser(autoPhone, autoPassword);
      navigate('/');
    } catch (error) {
      console.error('Auto login failed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoginFormSubmit = async (e: FormEvent) => {
    e.preventDefault();

    setIsSubmitted(true);

    let hasError = false;

    if (!isValidPhoneNumber(phoneNumber)) {
      setPhoneNumberError('Invalid phone number');
      hasError = true;
    } else {
      setPhoneNumberError('');
    }

    if (!password || password.length < 6) {
      setPasswordError('Password must be at least 6 characters long');
      hasError = true;
    } else {
      setPasswordError('');
    }

    if (hasError) return;

    setIsLoading(true);

    try {
      await authContext?.loginUser('+30' + phoneNumber, password);
    } catch (error) {
      console.error('Login failed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const isValidPhoneNumber = (phone: string) => {
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phone);
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.target.value);
    if (isSubmitted) {
      if (!isValidPhoneNumber(e.target.value)) {
        setPhoneNumberError('Invalid phone number');
      } else {
        setPhoneNumberError('');
      }
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    if (isSubmitted) {
      if (!e.target.value || e.target.value.length < 6) {
        setPasswordError('Password must be at least 6 characters long');
      } else {
        setPasswordError('');
      }
    }
  };

  const handlePasswordToggle = () => {
    setShowPassword((prevState) => !prevState);
  };
  return (
    <div className='h-screen flex items-center justify-center bg-cover' style={{ backgroundImage: `url(${driverImg})` }}>
      <div className='absolute top-0 left-0 bg-black h-full w-full bg-opacity-30'></div>
      <div 
        className='grid grid-cols-1 rounded-md shadow-md border-neutral-100 min-w-[380px] select-none relative'
        >
        <div className='absolute top-[-28px] w-[380px] transform w-full flex items-center justify-center z-20'>
          <div className='flex flex-col text-main justify-center mb-2 bg-white z-20 px-4 py-[5px] rounded-md shadow-md w-auto'>
            <LogoSvg fill='#002fff' className='h-[2.4rem]' />
            <div className='text-[0.67rem] mt-[-10px]'>Powered by</div>
          </div>
        </div>
        <motion.div
          className='rounded-md flex items-start justify-center flex-col w-[95vw] m-auto sm:w-full px-6 pt-[39px] pb-6 bg-white z-10 relative overflow-hidden'
          // animate={{ height: contentHeight }}


        >
          <div className='w-full flex items-center justify-center mb-1'>
            <img src={prebookingLogo} alt="" className='h-[4rem]' />
          </div>
          <AnimatePresence mode="wait">
            <motion.div
              key={currentScreen}
              initial={{ opacity: 0, x: currentScreen === 'login' ? 100 : -100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: currentScreen === 'login' ? 100 : -100 }}
              transition={{ duration: 0.3 }}
              className='w-full'
            >
              {currentScreen === 'login' ? (
                <form onSubmit={handleLoginFormSubmit} className="w-full">
                  <div className="mb-5 relative w-full">
                    <label htmlFor="phoneNumber" className="text-black text-lg block mb-1">Phone Number</label>
                    <div className="flex">
                      <div className="bg-gray-200 p-2 rounded-l-md flex items-center justify-center">
                        +30
                      </div>
                      <input
                        type="tel"
                        id="phoneNumber"
                        name="phoneNumber"
                        placeholder="Enter your phone number"
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                        aria-invalid={isSubmitted && !!phoneNumberError}
                        aria-describedby="phone-error"
                        className={`w-full p-2 border-2 rounded-r-md text-black no-outline focus:outline-none active:outline-none focus:border-main ${isSubmitted && phoneNumberError ? 'border-red-500' : ''}`}
                      />
                    </div>
                    {isSubmitted && phoneNumberError && <p id="phone-error" className="text-red-500 text-xs absolute">{phoneNumberError}</p>}
                  </div>
                  <div className="mb-5 relative w-full">
                    <label htmlFor="password" className="text-black text-lg block mb-1">Password</label>
                    <div className="relative">
                      <input
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        name="password"
                        placeholder="Enter Password"
                        value={password}
                        onChange={handlePasswordChange}
                        aria-invalid={isSubmitted && !!passwordError}
                        aria-describedby="password-error"
                        className={`w-full p-2 border-2 rounded text-black no-outline focus:outline-none active:outline-none focus:border-main ${isSubmitted && passwordError ? 'border-red-500' : ''}`}
                      />
                      <div
                        className="absolute top-0 h-full flex items-center justify-center transform right-3 cursor-pointer"
                        onClick={handlePasswordToggle}
                      >
                        <IonIcon icon={showPassword ? 'eye-off' : 'eye'} className="h-5 w-5 text-gray-700" />
                      </div>
                    </div>
                    {isSubmitted && passwordError && <p id="password-error" className="text-red-500 text-xs absolute">{passwordError}</p>}
                  </div>
                  <button
                    type="submit"
                    disabled={isLoading}
                    className={`w-full px-4 py-2 bg-main text-white cursor-pointer mt-3 text-m font-semibold rounded transition-colors shadow-md hover:bg-[#0000ff] duration-200 focus:outline-none ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                  >
                    {isLoading ? (
                      <IonSpinner name="crescent" className='h-[18px]' />
                    ) : (
                      'Login'
                    )}
                  </button>
                  <div className="w-full text-right mt-3">
                    <span
                      className="text-sm text-main hover:underline cursor-pointer"
                      onClick={handleForgotPasswordClick}
                    >
                      Forgot Password?
                    </span>
                  </div>
                  <div className='w-full bg-neutral-300 h-[1px] my-3'></div>
                  <a
                    href="https://taxi.gr/drivers-form"
                    target='_blank'
                    className="w-full block px-4 py-2 bg-gray-300 text-gray-700 cursor-pointer mt-3 text-m font-semibold rounded transition-colors shadow-md hover:bg-gray-400 focus:outline-none text-center"
                  >
                    Become a Driver
                  </a>
                </form>
              ) : (
                <ForgotPasswordScreen onBack={handleBackToLogin} />
              )}
            </motion.div>
          </AnimatePresence>
        </motion.div>
      </div>
      <Toaster />
    </div>
  );
}

export default LoginPage;