import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, useLocation, Navigate, useNavigate } from 'react-router-dom';
import Navigation from './Components/Navigation/Navigation';
import DriverReservationsPage from './Pages/DriverReservationsPage/DriverReservationsPage';
import { ReservationsProvider } from './utils/ReservationsContext/ReservationsContext';
import AllPreBookings from './Pages/AllPreBookings/AllPreBookings';
import { AuthProvider, AuthContext } from './utils/AuthContext';
import LoginPage from './Pages/LoginPage/LoginPage';
import PrivateRoute from './utils/PrivateRoute';

function AppContent() {
  const [pageTitle, setPageTitle] = useState('');
  const location = useLocation();
  const authContext = React.useContext(AuthContext);
  // Handle the case where context might be undefined
  const driverId = authContext?.driverId ?? null;

  useEffect(() => {
    switch(location.pathname) {
      case '/':
        setPageTitle('Διαθέσιμα Ραντεβού');
        break;
      case '/my-bookings':
        setPageTitle('Τα Ραντεβού μου');
        break;
      default:
        setPageTitle('');
    }
  }, [location.pathname]);

  return (
    <ReservationsProvider driverId={driverId}>
      <div className='dark:bg-neutral-900 dark:text-neutral-100 min-h-screen'>
        <Navigation pageTitle={pageTitle} />
        <Routes>
          <Route path="/" element={<AllPreBookings />} />
          <Route path="/my-bookings" element={<DriverReservationsPage />} />
        </Routes>
      </div>
    </ReservationsProvider>
  );
}

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/*"
            element={
              <PrivateRoute authenticationPath="/login">
                <AppContent />
              </PrivateRoute>
            }
          />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;